$('.csBasket---content .dropdown').on('show.bs.dropdown', function(e) {
    if (!e || !e.currentTarget) {
        return;
    }
    var target = e.currentTarget;
    var $target = $(target);
    var form = $target.closest('form');
    var elementOrigin = form.parent();
    $target.data('elementOrigin', elementOrigin);
    $target.data('elementForm', form);
    var offsetTop = $('html').scrollTop();
    var bounds = target.getBoundingClientRect();
    var $wrapper = $('body');
    $target.css(
        {
            position: 'absolute',
            left: bounds.left + 'px',
            top: bounds.top + offsetTop + 'px',
            width: bounds.width + 'px',
        }
    );
    $wrapper.append(form);
});

$('.csBasket---content .dropdown').on('hidden.bs.dropdown', function(e) {
    if (!e || !e.currentTarget) {
        return;
    }
    var target = e.currentTarget;
    var $target = $(target);
    var elementForm = $target.data('elementForm');
    var elementOrigin = $target.data('elementOrigin');
    if (elementForm && elementOrigin) {
        elementOrigin.append(elementForm);
        target.removeAttribute('style');
    }
});
$(document).ready(function () {
    'use strict';

    function showDD(target) {
        var $target = $(target);
        var form = $target.closest('form');
        var elementOrigin = form.parent();
        $target.data('elementOrigin', elementOrigin);
        $target.data('elementForm', form);
        var offsetTop = $('html').scrollTop();
        var bounds = target.getBoundingClientRect();
        var $wrapper = $('body');
        $target.css(
            {
                position: 'absolute',
                left: bounds.left + 'px',
                top: bounds.top + offsetTop + 'px',
                width: bounds.width + 'px',
            }
        );
        $wrapper.append(form);
    }
    function hideDD(target) {
        var $target = $(target);
        var elementForm = $target.data('elementForm');
        var elementOrigin = $target.data('elementOrigin');
        if (elementForm && elementOrigin) {
            elementOrigin.append(elementForm);
            target.removeAttribute('style');
        }
    
    }

    $.csInit(function (e) {
        e.container.find('.csBasket-content .dropdown').on('show.bs.dropdown', function(ev) {
            if (!ev || !ev.currentTarget) {
                return;
            }
            showDD(ev.currentTarget);
        });
        e.container.find('.csBasket-content .dropdown').on('hidden.bs.dropdown', function(ev) {
            if (!ev || !ev.currentTarget) {
                return;
            }
            hideDD(ev.currentTarget);
        });
    });
});