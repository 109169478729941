/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */

/* csMobileNav */
(function ($) {
    'use strict';


    var methods = {

        init : function(menu, trigger, opts) {
            var self = this;

            this.settings = $.extend({
                type : 'cover', /* overlap || cover */
                levelSpacing : 40,
                backClass : 'mp-back',
            }, opts );

            this.el = menu;
            this.trigger = trigger;

            /* the moving elements */
            this.header = document.getElementById( 'mp-header' );
            this.menu = document.getElementById( 'mp-menu' ); 
            this.footer = document.getElementById( 'mp-footer' );
            this.wrapper = document.getElementById( 'mp-pusher' );

            /* if menu is open or not */
            this.open = false;

            /* level depth */
            this.level = 0;

            /* the mp-level elements */
            this.levels = Array.prototype.slice.call( this.el.querySelectorAll( 'div.mp-level' ) );

            /* save the depth of each of these mp-level elements */
            this.levels.forEach( function( el ) { 
                el.setAttribute( 'data-level', getLevelDepth( el, self.el.id, 'mp-level' ) );
            });

            /* the menu items */
            this.menuItems = Array.prototype.slice.call( this.el.querySelectorAll( 'li' ) );

            this.timeoutID = '';

            /* if type == "cover" these will serve as hooks to move back to the previous level */
            this.levelBack = Array.prototype.slice.call( this.el.querySelectorAll( '.' + this.settings.backClass ) );

            /* event type (if mobile use touch events) */
            this.eventtype = mobilecheck() ? 'touchstart' : 'click';

            /* add the class mp-overlap or mp-cover to the main element depending on options.type */
            $(this.el).addClass( 'mp-' + this.settings.type );

            $(this.menu).closest('.csSmartNav').removeAttr('style');

            /* Events */
            // $(window).on('resize', function() {
            //     if (window.outerWidth > 768 && self.open) {
            //         methods.resetMenu.apply( self, arguments );
            //     }
            // });
            //
            // if(window.orientation !== undefined){
            //     $(window).on("orientationchange", function(){
            //         methods.resetMenu.apply( self, arguments );
            //     });
            // }

            /* open (or close) the menu */
            $(this.trigger).on( "click", function( ev ) {
                ev.preventDefault();
                if( self.open ) {
                    methods.resetMenu.apply( self, arguments );
                } else {
                    methods.showMenu.apply( self, arguments );

                    removeScrollControl($('#mp-menu .mp-level[data-level="1"]'));
                    addScrollControl($('#mp-menu .mp-level[data-level="1"]'));
                }
            });

            /* opening a sub level menu */
            this.menuItems.forEach( function( el ) {

                /* check if it has a sub level */
                var subLevel = el.querySelector( 'div.mp-level' );
                if( subLevel ) {

                    el.querySelector( 'a' ).addEventListener( "click", function( ev ) {
                        ev.preventDefault();

                        $('#mp-menu .mp-level[data-level="1"]');

                        removeScrollControl();

                        addScrollControl($(ev.target).siblings('.scroll-y'));

                        $('#mp-menu > div').scrollTop(0);

                        var level = closest( el, 'mp-level' ).getAttribute( 'data-level' );

                        if( self.level <= level ) {
                            ev.stopPropagation();
                            $(el).closest('.mp-level').addClass('mp-level-overlay');
                            methods.showMenu.apply( self, [subLevel] );
                        }
                    });
                }
            });

            /* closing the sub levels by clicking on the visible part of the level element */
            this.levels.forEach( function( el ) {
                el.addEventListener( "click", function( ev ) {
                    ev.stopPropagation();
                    var level = el.getAttribute( 'data-level' );
                    if( self.level > level ) {
                        self.level = level;
                        self.hideMenu();
                    }
                } );
            } );

            /* by clicking on a specific element */
            this.levelBack.forEach( function( el ) {
                el.addEventListener( "click", function( ev ) {
                    ev.preventDefault();
                    var level = closest( el, 'mp-level' ).getAttribute( 'data-level' );
                    if( self.level <= level ) {

                        ev.stopPropagation();
                        self.level = closest( el, 'mp-level' ).getAttribute( 'data-level' ) - 1;

                        if(self.level === 0){
                            methods.resetMenu.apply( self, arguments );

                        }else{
                            methods.hideMenu.apply( self, arguments );
                        }

                        removeScrollControl();
                        addScrollControl($('#mp-menu .mp-level[data-level="1"]'));
                    }
                });
            });

        },
        showMenu : function(subLevel) {
            if ($('#sidenav-overlay').length === 0) {
                var overlay = $('<div id="sidenav-overlay"></div>');
                var that = this;
                overlay.css('opacity', 1).click( function(){
                    methods.resetMenu.apply( that, arguments );
                    overlay.remove();
                });
                $('body').append(overlay);
            }

            $('html').css('overflow', 'hidden');
            $('#mp-menu > div').scrollTop(0);

            $(document).off('touchmove', scrollBlock);
            $(document).on('touchmove', scrollBlock);

            document.activeElement.blur();

            /* increment level depth */
            ++this.level;

            $('body').addClass('mp-animate');
            $('body').addClass('mp-pushed');

            methods.setTransform( 'translate3d(' + this.el.offsetWidth + 'px,0,0)', this.header);
            methods.setTransform( 'translate3d(' + this.el.offsetWidth + 'px,0,0)', this.footer);
            methods.setTransform( 'translate3d(' + this.el.offsetWidth + 'px,0,0)', this.wrapper);
            methods.setTransform( 'translate3d(0px,0,0)', $('.csSmartNav')[0]);

            /* add class mp-pushed to main wrapper if opening the first time */
            if( this.level === 1 ) {
                $(this.wrapper).addClass('mp-pushed');
                this.open = true;
            }
            /* add class mp-level-open to the opening level element */
            $(subLevel || this.levels[0]).addClass('mp-level-open');

        },
        hideMenu : function( ) {
            methods.toggleLevels.apply( this, arguments );
        },
        resetMenu : function(  ) {

            $(document).off('touchmove', scrollBlock);
            $('html, body').removeAttr('style');

            if ($('#sidenav-overlay').length) {
                $('#sidenav-overlay').remove();
            }

            removeScrollControl();

            methods.setTransform( 'translate3d(0,0,0)', this.header);
            methods.setTransform( 'translate3d(0,0,0)', this.footer);
            methods.setTransform( 'translate3d(0,0,0)', this.wrapper);
            methods.setTransform( 'translate3d(-300px,0,0)', $('.csSmartNav')[0]);

            this.level = 0;

            methods.toggleLevels.apply( this, arguments );
            this.open = false;

            $('main').off("webkitTransitionEnd transitionend msTransitionEnd oTransitionEnd", cssTransEnd);
            $('main').on("webkitTransitionEnd transitionend msTransitionEnd oTransitionEnd", cssTransEnd);
        },
        setTransform : function(val, el ) {
            el = el || this.wrapper;
            el.style.WebkitTransform = val;
            el.style.MozTransform = val;
            el.style.transform = val;
        },
        /* removes classes mp-level-open from closing levels */
        toggleLevels : function() {
            for( var i = 0, len = this.levels.length; i < len; ++i ) {
                var levelEl = this.levels[i];
                if( levelEl.getAttribute( 'data-level' ) >= this.level + 1 ) {
                    $(levelEl).removeClass('mp-level-open');
                    $(levelEl).removeClass('mp-level-overlay');
                }
                else if( Number( levelEl.getAttribute( 'data-level' ) ) === this.level ) {
                    $(levelEl).removeClass('mp-level-overlay');
                }
            }
        }
    };




    $.fn.csSmartNav = function(methodOrOptions) {
        if ( methods[methodOrOptions] ) {
            return methods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {
            // Default to "init"
            return methods.init.apply( this, arguments );
        } else {
            $.error( 'Method ' +  methodOrOptions + ' does not exist on jQuery.csSmartNav' );
        }    
    };



    /**
    * returns the depth of the element "e" relative to element with id=id
    * for this calculation only parents with classname = waypoint are considered 
    **/
    function getLevelDepth( e, id, waypoint, cnt ) {
        cnt = cnt || 0;
        if ( e.id.indexOf( id ) >= 0 ){
            return cnt;
        }
        if($(e).hasClass(waypoint) ) {
            ++cnt;
        }
        return e.parentNode && getLevelDepth( e.parentNode, id, waypoint, cnt );
    }

    function scrollBlock(e){
        e.preventDefault();
    }

    function removeScrollControl($elems){
        if($elems === undefined || $elems.length === 0){
            $(".scroll-y").off('touchmove', handleTouchmove);
            $(".scroll-y").off('touchstart', handleTouchstart);
        } else {
            $elems.off('touchstart', handleTouchstart);
            $elems.off('touchmove', handleTouchmove);
        }
    }

    function addScrollControl($elems){
        $elems.on("touchstart", handleTouchstart);
        $elems.on('touchmove', handleTouchmove);
    }

    function cssTransEnd(){
        $('body').removeClass('mp-animate mp-pushed');
        $('#mp-pusher').removeClass('mp-pushed');
        $('main').off("webkitTransitionEnd transitionend msTransitionEnd oTransitionEnd", cssTransEnd);
    }

    function handleTouchstart (event) {
        var target = event.target;
        /* If the element is scrollable (content overflows), then... */

        if (target.scrollHeight !== target.clientHeight) {
            /* If we're at the top, scroll down one pixel to allow scrolling up */
            if (target.scrollTop === 0) {
                target.scrollTop = 1;
            }
            /* If we're at the bottom, scroll up one pixel to allow scrolling down */
            if (target.scrollTop === target.scrollHeight - target.clientHeight) {
                target.scrollTop = target.scrollHeight - target.clientHeight - 1;
            }
        }
        /* Check if we can scroll */
        target.allowUp = target.scrollTop > 0;
        target.allowDown = target.scrollTop < (target.scrollHeight - target.clientHeight);
        target.lastY = event.originalEvent.pageY;
    }


    function handleTouchmove (e) {
        var event = e.originalEvent;
        var target = e.target;
        var up = event.pageY > target.lastY;
        var down = !up;
        target.lastY = event.pageY;

        if ((up && target.allowUp) || (down && target.allowDown)) {
            event.stopPropagation();
        } else {
            event.preventDefault();
        }
    }


    function mobilecheck() {
        var check = false;

        (function(a) {
            if (/(android|ipad|playbook|silk|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))){
                check = true;
            }
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }


    /* returns the closest element to 'e' that has class "classname" */
    function closest(e, classname) {
        if ($(e).hasClass(classname)) {
            return e;
        }
        return e.parentNode && closest(e.parentNode, classname);
    }

}(jQuery));

$(document).ready( function() {
    'use strict';

    var menu = document.getElementById('mp-menu');
    var trigger = document.getElementById('mp-trigger');

    if(menu && trigger){
        $('.csSmartNav').csSmartNav(menu, trigger);
    }
});
