/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, undef:true, unused:true, curly:true, browser:true, indent:2, jquery:true */
/*jshint devel: true */
/* ####  csAudio.js ###
 */
(function () {
    'use strict';

    function initPollForm(container) {
      container.find('form.quick-poll-form').each(function() {
        var form = $(this);

        function checkAll() {
          if(form.find('.js-poll_result').length > 0) {
            form.find('input[type="submit"], button[type="submit"]').attr('disabled', 'disabled');
            return;
          }
          var any = form.find('input[type="checkbox"]').length > 0;
          if(any) {
            var none = form.find('input[type="checkbox"]:checked').length === 0;
            if(none) {
              form.find('input[type="submit"], button[type="submit"]').attr('disabled', 'disabled');
            } else {
              form.find('input[type="submit"], button[type="submit"]').removeAttr('disabled');
            }
          }
        }

        form.find('input[type="checkbox"]').each(function() {
          $(this).change(function() {
            checkAll();
          });
        });

        checkAll();

        form.find('input[type="submit"], button[type="submit"]').click(function(e) {
          if ($(this).attr('disabled')) {
            e.stopPropagation();
            e.preventDefault();
          }
        });
      });
    }

    $(document).ready(function() {
      $.csInit(function(e) {
        e.container.each(function() {
          initPollForm($(this));
        });
      });
    });

  })();
