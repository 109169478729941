/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */
/* csScripts.js */
$(document).ready(function() {
    'use strict';

    /* client only / keep in sync with session conf */
    var pwValidator = function($checkboxContainer, $userInput) {
        $userInput.on('keyup', function(event) {
            var value = event.target.value;
            /* special char */
            $checkboxContainer.find('input[data-validator-special]').prop('checked', value.match(/\W+/) !== null ? true : false);
            /* numer */
            $checkboxContainer.find('input[data-validator-number]').prop('checked', value.match(/\d+/) !== null ? true : false);
            /* lowercase */
            $checkboxContainer.find('input[data-validator-lower]').prop('checked', value.match(/[a-z]/) !== null ? true : false);
            /* uppercase */
            $checkboxContainer.find('input[data-validator-upper]').prop('checked', value.match(/[A-Z]/) !== null ? true : false);
            /* minlenght */
            $checkboxContainer.find('input[data-validator-min]').prop('checked', value.length >= 8 ? true : false);
        });
    };

    var initPwValidator = function(container, containerClass) {
        var $checkboxContainer = null;
        var $userInput = null;

        container.find(containerClass).each(function() {
            if ($(container).find('.js-pw-validator-input')) {
                $userInput = $(container).find('.js-pw-validator-input');
                $checkboxContainer = $(this);
                pwValidator($checkboxContainer, $userInput);
            }
        });
    };

    $.csInit(function(e) {
        initPwValidator(e.container, ".pw-check");
    });
});
