/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:4, jquery:true */
/*jshint devel: true */
/* csHtml5upload-crop.js */
$.fn.html5Upload = function () {
    'use strict';
    var widget = this,
        holder = widget.find('.csAccount__image__holder'),
        ctrlContainer = widget.find('.csAccount__image__ctrl'),
        ctrlCrop = widget.find('.pic-crop'),
        ctrlRemove = widget.find('.pic-remove'),
        ctrlCancel = widget.find('.pic-cancel'),
        ctrlSubmit = widget.find('.pic-submit'),
        inputToken = widget.find('input[name="__req_s"]'),
        noPicElement = widget.find('.no-userpic'),
        file = widget.find('.csAccount__image__file'),
        csProgress = widget.find('.csProgress'),
        jcrop_api,
        tests = {
        filereader: typeof FileReader !== 'undefined',
        dnd: 'draggable' in document.createElement('span'),
        formdata: !!window.FormData,
        progress: 'upload' in new XMLHttpRequest()
    },
        support = {
        filereader: widget.find('.filereader'),
        formdata: widget.find('.formdata'),
        progress: widget.find('.progress'),
    };

    var clickFileInput = function (e) {
        e.preventDefault();
        file.val(undefined); // reset file value, so submit is triggered
        file.click();
    };

    var dragOver = function (e) {
        widget.addClass('hover');
        e.preventDefault();
    };

    var dragLeave = function (e) {
        widget.removeClass('hover');
        widget.removeClass('dropped');
        e.preventDefault();
    };

    var showProgress = function () {
        csProgress.removeClass('hidden');
    };

    var showPreview = function (res) {
        var variants = readVariantDataFrom(res);
        if (!variants || (typeof variants !== 'object')) {
          return;
        }
        var b64 = variants.variant_base64;

        removeActionHandler();

        var imgBase64 = new Image();
        var mime = variants.variant_mimetype;

        imgBase64.src = 'data:' + mime + ';base64,' + b64;
        imgBase64.className = "rounded-circle img-fluid";

        holder.data('src-master', 'data:' + variants.master_mimetype + ';base64,' + variants.master_base64);
        holder.data('width', variants.master_width);
        holder.data('height', variants.master_height);
        holder.data('src-variant', imgBase64.src);
        holder.append(imgBase64);

        imgBase64.addEventListener('load', function () {
            widget.find('button').removeAttr('disabled');
            holder.removeClass('dropped');
            widget.removeClass('missing');
            widget.removeClass('dropped');
            widget.removeClass('crop-view');
            widget.addClass('isset');
        });
    };

    var drop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        file.val(undefined); // reset file value, so submit is triggered
        readfiles(e.dataTransfer, function (response) {
            showProgress(100);
            var error = validateData(response);

            if (!error) {
                showPreview(response);
                $('.csAccount__uploader__status').find('.message').remove();
            } else {
                showError(error);
            }
        });
    };

    var submitForm = function (e) {
        e.preventDefault();
        try {
            if (file[0] && file[0].files[0]) {
                readfiles(file[0], function (response) {
                    showProgress(100);
                    var error = validateData(response);

                    ctrlContainer.find('.message').remove();

                    if (!error) {
                        showPreview(response);
                    } else {
                        showError(error);
                    }
                });
            }
        } finally {
            file.val(undefined);
        }
    };

    function removeActionHandler() {
        noPicElement[0].removeEventListener('dragover', dragOver, false);
        noPicElement[0].removeEventListener('dragleave', dragLeave, false);
        noPicElement[0].removeEventListener('drop', drop, false);
        noPicElement.off('click', clickFileInput);
        file.off('change', submitForm);
    }

    function addActionHandler() {
        noPicElement[0].addEventListener('dragover', dragOver, false);
        noPicElement[0].addEventListener('dragleave', dragLeave, false);
        noPicElement[0].addEventListener('drop', drop, false);
        noPicElement.on('click', clickFileInput);
        file.on('change', submitForm);
    }

    function setNoPicture() {
        widget.addClass('missing');
        widget.removeClass('isset');
        widget.removeClass('dropped');

        removeActionHandler();
        addActionHandler();

        if (holder.find('img')) {
            holder.find('img').remove();
        }
    }

    var crop = {
        coords: {},
        widget: {
            width: '',
            height: ''
        },
        setCoords: function (c) {
            crop.coords.x = c.x;
            crop.coords.x2 = c.x2;
            crop.coords.y = c.y;
            crop.coords.y2 = c.y2;
            crop.coords.w = c.w;
            crop.coords.h = c.h;

            $('#x').val(c.x);
            $('#y').val(c.y);
            $('#w').val(c.w);
            $('#h').val(c.h);
            $('#x2').val(c.x2);
            $('#y2').val(c.y2);
        },

        getCoords: function () {
            return crop.coords;
        },

        getTrueCoords: function () {
            var imgW = holder.data('width');
            var f = imgW / $(holder).find('img').width();

            var output = {
                'w': Math.floor(crop.coords.w * f),
                'h': Math.floor(crop.coords.h * f),
                'x': Math.floor(crop.coords.x * f),
                'y': Math.floor(crop.coords.y * f),
                'y2': Math.floor(crop.coords.y2 * f),
                'x2': Math.floor(crop.coords.x2 * f)
            };
            return output;
        }
    };

    var old = false;
    'filereader formdata progress'.split(' ').forEach(function (api) {
        if (tests[api] === false) {
            support[api].className = 'hidden';
            old = true;
        }
    });

    var handleDelete = function (e) {
        e.preventDefault();
        showProgress(0);

        holder.find('img').fadeOut('fast', function () {
            widget.addClass('dropped');
            widget.removeClass('isset');
            widget.removeClass('missing');
            var xhr = new XMLHttpRequest(),
                formData = new FormData();
            xhr.open('POST', '?action.removeImage');
            if (inputToken && inputToken[0] && inputToken[0].value) {
                formData.append('__req_s', inputToken[0].value); 
            }
            widget.find('button').attr('disabled', 'true');
            var onReadStateChangeActive = true;
            xhr.onreadystatechange = function () {
                if (!onReadStateChangeActive) {
                    return;
                }
                if (xhr.readyState === 4) {
                    setNoPicture();
                    widget.find('button').removeAttr('disabled');
                    onReadStateChangeActive = false;
                    return;
                }
                var resp = readLastObjectFromResponse(xhr.response);
                if (resp && resp.step === resp.steps) {
                    setNoPicture();
                    widget.find('button').removeAttr('disabled');
                    onReadStateChangeActive = false;
                    return;
                }

            };
            xhr.send(formData);
        });

        $('#x').val(0);
        $('#y').val(0);
        $('#w').val(0);
        $('#h').val(0);
        $("#firstCrop").val("1");
    };

    var handleCropCancel = function (e) {
        e.preventDefault();
        jcrop_api.destroy();
        widget.removeClass('crop-view');
        widget.addClass('isset');
        var lastPicture = new Image();
        lastPicture.src = holder.data('src-variant');
        holder.find('img').remove();
        holder.append(lastPicture);
        $("#firstCrop").val("");
    };

    

    var handleCropView = function (e) {
        e.preventDefault();

        widget.addClass('crop-view');

        var img = new Image();
        img.src = holder.data('src-master');
        img.setAttribute('class', 'to-load');

        holder.find('img').remove();
        holder.append(img);
        widget.find('button').attr('disabled', 'true');

        img.onload = function () {
            var imgWidth = holder.find('img').width();
            var imgHeight = holder.find('img').height();
            var imgW = holder.data('width');
            var f = imgW / imgWidth;
            var firstC = $('#firstCrop').val();
            var lx = parseInt($('#x').val(), 10);
            var ly = parseInt($('#y').val(), 10);
            var lw = parseInt($('#w').val(), 10);
            var lh = parseInt($('#h').val(), 10);
            var ida=[];

            if ((firstC === "1") && ((lx === 0) && (ly === 0) && (lw === 0) && (lh === 0))) {
                $('#w').val(imgWidth);
                $('#h').val(imgHeight);
                var dlw = parseInt($('#w').val(), 10);
                var dlh = parseInt($('#h').val(), 10);
                lx = Math.floor(((dlw - dlh) / 2));
                var xx2 = lx + dlw;
                var yy2 = ly + dlh;
                ly = Math.floor(((dlh - dlw) / 2));

                ida = [lx, ly, xx2, yy2];

            }else if (firstC === "1") {
                var xx1 = lx + lw;
                var yy1 = ly + lh;
                lx = Math.floor((lx / f));
                ly = Math.floor((ly / f));
                xx1 = Math.floor((xx1 / f));
                yy1 = Math.floor((yy1 / f));

                ida = [lx, ly, xx1, yy1];

            } else {
                var lx2 = parseInt($('#x2').val(), 10);
                var ly2 = parseInt($('#y2').val(), 10);
                ida = [lx, ly, lx2, ly2];
            }


            holder.find('img').Jcrop({
                    onChange: crop.setCoords,
                    onSelect: crop.setCoords,
                    aspectRatio: 1,
                    boxWidth: imgWidth,
                    setSelect: ida,
                },
                function () {
                    jcrop_api = this;
                    widget.find('button').removeAttr('disabled');
                    ctrlCancel.on('click', handleCropCancel);
                });
        };

    };

    var handleCropSubmit = function (e) {
        var xhr = new XMLHttpRequest(),
            formData = new FormData();
        if (inputToken && inputToken[0] && inputToken[0].value) {
            formData.append('__req_s', inputToken[0].value); 
        }
        var coReal = crop.getTrueCoords();

        $("#firstCrop").val("");

        jcrop_api.release();
        e.preventDefault();

        holder.find('img').remove();
        holder.find('.jcrop-holder').remove();

        widget.addClass('missing');
        widget.addClass('dropped');
        widget.removeClass('crop-view');
        widget.removeClass('isset');

        var url = '?action.updateCrop';
        url += '&crop.x=' + coReal.x;
        url += '&crop.x2=' + coReal.x2;
        url += '&crop.y=' + coReal.y;
        url += '&crop.y2=' + coReal.y2 ;
        if(coReal.w > 50) {
            url += '&crop.w=' + (coReal.w -2);
        }else{
            url += '&crop.w=' + (coReal.w );
        }
        if(coReal.h > 50) {
            url += '&crop.h=' + (coReal.h -2);
        }else{
            url += '&crop.h=' + (coReal.h );
        }
        url += '&t=' + new Date().getTime();

        xhr.open('POST', url, true);

        showProgress(1);

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                showPreview(xhr.responseText);
            }
        };

        xhr.addEventListener('progress', function (e) {
            var o = readLastObjectFromResponse(e.currentTarget.response);
            if (o && o.step) {
                showProgress(o.step);
                // progress.value = o.step;
            }
        }, false);

        xhr.send(formData);
    };


    if (!old) {

        /* bind controls */
        ctrlRemove.on('click', handleDelete);
        ctrlCrop.on('click', handleCropView);
        ctrlSubmit.on('click', handleCropSubmit);

        if (widget.hasClass('missing')) {
            setNoPicture();
        }

    }

    function showError(error) {
        showProgress(0);
        for (var i = 0; i < error.length; i++) {
            ctrlContainer.append('<p class="message error">' + error[i].message + '</p>');
        }
        setNoPicture();
    }

    function readLastObjectFromResponse(response) {
        var r = response.split('\n');

        for (var i = r.length - 1; i >= 0; i--) {
            try {
                var result = JSON.parse(r[i]);
                return result;
            } catch (e1) {
                // ignore
            }
        }
    }

    function readVariantDataFrom(response) {
        var r = response.split('\n');

        for (var i = r.length - 1; i >= 0; i--) {
            try {
                var result = JSON.parse(r[i]);
                if (result.master) {
                    return result;
                }
            } catch (e1) {
                // ignore
            }
        }
    }

    function validateData(response) {
        var r = response.split('\n');
        var responseFound = false;
        for (var i = r.length - 1; i >= 0; i--) {
            try {
                var result = JSON.parse(r[i]);
                responseFound = true;
                if (result.validation) {
                    return result.validation;
                }
            } catch (e1) {
            }
        }
      if (!responseFound) {
        return [{'message': 'unknown error'}];
      }
    }


    function readfiles(files, callback) {
        // read form data and post to server

        widget.addClass('dropped');
        widget.removeClass('hover');
        widget.removeClass('missing');

        ctrlContainer.find('.message').remove();

        removeActionHandler();

        if (tests.formdata) {
            var formData = new FormData();
            formData.append(file.attr('name'), files.files[0]);
            if (inputToken && inputToken[0] && inputToken[0].value) {
                formData.append('__req_s', inputToken[0].value); 
            }
            var xhr = new XMLHttpRequest();

            xhr.open('POST', '?action.createImage');

            xhr.addEventListener('progress', function (e) {

                var o = readLastObjectFromResponse(e.currentTarget.response);
                if (o && o.step) {
                    showProgress(o.step);
                    // progress.value = (o.step);
                }
            }, false);

            if (tests.progress) {
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        callback(xhr.responseText);
                    }
                };

                xhr.upload.onprogress = function (event) {
                    if (event.lengthComputable) {
                        var complete = (event.loaded / event.total * 100 || 0);
                        showProgress(complete);
                    }
                };
            }
            xhr.send(formData);
        }
    }
};

$(document).ready(function () {
    'use strict';

    $.csInit(function (initEvent) {
        if (initEvent.container.find('.csAccount__image').length > 0) {
            initEvent.container.find('.csAccount__image').html5Upload();
        }
    });

});
